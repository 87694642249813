<template>
  <section class="additional-fee">
    <page-header
      :back-to="{ name: 'additional-fee-management' }"
      title="Tambah Biaya Tambahan"
      class="mb-32"
    />

    <bg-grid>
      <bg-grid-item :col="7">
        <div class="additional-fee-form">
          <bg-field
            label="Nama Biaya"
            :error="validatedFeeName.error"
            :message="validatedFeeName.message"
          >
            <bg-input
              v-model="fee.fee_name"
              placeholder="Masukkan nama biaya"
              data-testid="input-fee-name"
              @input="handleInputFeeName"
            />
          </bg-field>
          <bg-card>
            <bg-grid>
              <bg-grid-item>
                <bg-field label="Tipe Pembayaran Biaya">
                  <bg-skeleton
                    v-if="isLoadingFormOptions"
                    width="100%"
                    height="32px"
                    data-testid="loader"
                  />
                  <bg-radio
                    v-else
                    v-for="(item, index) in formOptions.payment_type"
                    :key="index"
                    v-model="fee.payment_type"
                    :id="`payment-type-${index}`"
                    :label="formOptionsFormatter.payment_type[item]"
                    :custom-value="item"
                    class="mb-8"
                    :data-testid="`payment-type-radio-${index}`"
                    @input="handleInputFeeTypeMethod('type', $event)"
                  />
                </bg-field>
              </bg-grid-item>
              <bg-grid-item>
                <bg-field label="Waktu Penentuan Harga Biaya">
                  <bg-skeleton
                    v-if="isLoadingFormOptions"
                    width="100%"
                    height="32px"
                    data-testid="loader"
                  />
                  <bg-radio
                    v-else
                    v-for="(item, index) in pricingMethods"
                    :key="index"
                    v-model="fee.pricing_method"
                    :id="`pricing-time-${index}`"
                    :label="formOptionsFormatter.pricing_method[item]"
                    :custom-value="item"
                    class="mb-8"
                    :data-testid="`pricing-type-radio-${index}`"
                    @input="handleInputFeeTypeMethod('method', $event)"
                  />
                </bg-field>
              </bg-grid-item>
            </bg-grid>
          </bg-card>
          <bg-card>
            <bg-grid>
              <bg-grid-item>
                <bg-field label="Satuan Waktu Biaya">
                  <bg-skeleton
                    v-if="isLoadingFormOptions"
                    width="100%"
                    height="32px"
                    data-testid="loader"
                  />
                  <bg-checkbox
                    v-else
                    v-for="(item, index) in formOptions.time_unit"
                    :key="index"
                    v-model="fee.time_unit"
                    :id="`time-unit-${index}`"
                    :label="formOptionsFormatter.time_unit[item]"
                    :value="item"
                    class="mb-8"
                    :data-testid="`time-unit-checkbox-${index}`"
                  />
                </bg-field>
              </bg-grid-item>
              <bg-grid-item>
                <bg-field label="Satuan Besaran Biaya">
                  <bg-skeleton
                    v-if="isLoadingFormOptions"
                    width="100%"
                    height="32px"
                    data-testid="loader"
                  />
                  <bg-checkbox
                    v-else
                    v-for="(item, index) in formOptions.fee_unit"
                    :key="index"
                    v-model="fee.fee_unit"
                    :id="`fee-unit-${index}`"
                    :label="formOptionsFormatter.fee_unit[item]"
                    :value="item"
                    class="mb-8"
                    :data-testid="`fee-unit-checkbox-${index}`"
                  />
                </bg-field>
              </bg-grid-item>
            </bg-grid>
          </bg-card>
          <bg-card>
            <bg-grid>
              <bg-grid-item>
                <bg-field label="Penyewa Bisa Pilih Mandiri">
                  <bg-skeleton
                    v-if="isLoadingFormOptions"
                    width="47%"
                    height="32px"
                    data-testid="loader"
                  />
                  <bg-radio
                    v-else
                    v-for="(item, index) in formOptions.tenant_can_choose"
                    :key="index"
                    v-model="fee.is_chooseable_by_tenant"
                    :id="`tenant-can-choose-${index}`"
                    :label="formOptionsFormatter.tenant_can_choose[item]"
                    :custom-value="item"
                    class="mb-8"
                    :disabled="!isTenantOptionActive"
                    :data-testid="`tenant-can-choose-radio-${index}`"
                  />
                </bg-field>
              </bg-grid-item>
              <bg-grid-item v-if="fee.is_chooseable_by_tenant">
                <bg-field label="Fase Penyewa Pilih Biaya">
                  <bg-checkbox
                    v-for="(item, index) in formOptions.funnel"
                    :key="index"
                    v-model="fee.funnel"
                    :id="`funnel-${index}`"
                    :label="formOptionsFormatter.funnel[item]"
                    :value="item"
                    class="mb-8"
                    :data-testid="`funnel-checkbox-${index}`"
                  />
                </bg-field>
              </bg-grid-item>
            </bg-grid>
          </bg-card>
        </div>
      </bg-grid-item>
    </bg-grid>

    <bg-button
      variant="primary"
      data-testid="tambah-btn"
      :disabled="!isTambahBtnDisabled"
      @click="isShowConfirmationModal = true"
    >
      Tambah
    </bg-button>

    <bg-modal
      v-model="isShowConfirmationModal"
      title="Yakin menambah biaya tambahan ini?"
      description="
        Pastikan kebenaran dari pengaturan biaya tambahan ini sebelum disimpan
      "
      :close-on-click-backdrop="false"
      :closable="!isLoadingAddData"
    >
      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            size="lg"
            :loading="isLoadingAddData"
            @click="isShowConfirmationModal = false"
          >
            Kembali
          </bg-button>
          <bg-button
            variant="primary"
            size="lg"
            :loading="isLoadingAddData"
            data-testid="simpan-btn"
            @click="handleCreateAdditionalFee"
          >
            Simpan
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgSkeleton,
  BgInput,
  BgCard,
  BgRadio,
  BgCheckbox,
  BgButton,
  BgModal,
} from 'bangul-vue';
import { endpoints } from '@admin/api/endpoints/additional-fee.js';
import { additionalFeeOptionsFormatter } from '../_consts/consts.js';

export default {
  name: 'AddAdditionalFee',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSkeleton,
    BgInput,
    BgCard,
    BgRadio,
    BgCheckbox,
    BgButton,
    BgModal,
    PageHeader: () => import('@admin_molecules/PageHeader'),
  },

  data() {
    return {
      fee: {
        fee_name: '',
        payment_type: null,
        pricing_method: null,
        time_unit: [],
        fee_unit: [],
        is_chooseable_by_tenant: null,
        funnel: [],
      },
      isLoadingFormOptions: true,
      feeNameInputTimeout: null,
      feeNameValidation: {
        hasBeenValidated: false,
        hasError: false,
        message: '',
      },
      formOptions: {},
      formOptionsFormatter: additionalFeeOptionsFormatter,
      isTenantOptionActive: true,
      isShowConfirmationModal: false,
      isLoadingAddData: false,
    };
  },

  computed: {
    pricingMethods() {
      const selectablePricingMethod = this.fee.payment_type === 'fixed' ?
        this.formOptions.pricing_method.filter(item => item === 'predefined') :
        this.formOptions.pricing_method; 

      return selectablePricingMethod;
    },

    validatedFeeName() {
      const validation = {
        error: false,
        message: '',
      };

      if (this.feeNameValidation.hasError) {
        validation.error = true;
        validation.message = this.feeNameValidation.message;
      }
      if (this.fee.fee_name.length > 60) {
        validation.error = true;
        validation.message = 'Panjang karakter maksimal adalah 60 karakter.';
      }
      if (!this.fee.fee_name.length) {
        validation.error = false;
        validation.message = ''; 
      }

      return validation;
    },

    isTambahBtnDisabled() {
      let mandatoryField = null;

      // eslint-disable-next-line no-unused-vars
      const { funnel, time_unit, fee_unit, ...rest } = this.fee;
      mandatoryField = rest;

      const isAllFieldFilled = !Object.values(mandatoryField).some(
        value => value === null || value === ''
      );

      if (this.fee.is_chooseable_by_tenant && !funnel.length) {
        return false;
      };

      return isAllFieldFilled &&
        (time_unit.length || fee_unit.length) &&
        (this.feeNameValidation.hasBeenValidated &&
          !this.feeNameValidation.hasError);
    },
  },

  mounted() {
    this.fetchFormOptions();
  },

  methods: {
    async fetchFormOptions() {
      this.isLoadingFormOptions = true;
      const response = await endpoints.getAdditionalFeeOptions();

      if (response) {
        this.formOptions = {
          ...response,
          tenant_can_choose: [true, false],
        };
      }

      this.isLoadingFormOptions = false;
    },

    handleInputFeeName() {
      this.feeNameValidation.hasBeenValidated = false;
      this.feeNameValidation.hasError = false;
      this.feeNameValidation.message = '';

      clearTimeout(this.feeNameInputTimeout);

      if (this.fee.fee_name.length < 60) {
        this.feeNameInputTimeout = setTimeout(() => {
          this.validateFeeName();
        }, 1000);
      }
    },

    async validateFeeName() {
      const response = await endpoints.validateFeeName({
        fee_name: this.fee.fee_name,
      });

      if (response.status === 400 || response.status === 422) {
        this.feeNameValidation.hasError = true;
        this.feeNameValidation.message = response.data?.error;
      }

      this.feeNameValidation.hasBeenValidated = true;
    },

    handleInputFeeTypeMethod(inputType, value) {
      if (inputType === 'method') {
        if (value === 'defined_by_billing') {
          this.fee.is_chooseable_by_tenant = false;
          this.isTenantOptionActive = false;
        } else {
          this.fee.is_chooseable_by_tenant = null;
          this.isTenantOptionActive = true;
        }
      } else {
        this.fee.pricing_method = 'predefined';
      }
    },

    async handleCreateAdditionalFee() {
      this.isLoadingAddData = true;

      const response = await endpoints.createAdditionalFee(this.fee);

      if (response) {
        this.$router.push({ name: 'additional-fee-management' });
        this.$toast.show('Data berhasil ditambahkan.');
      }

      this.isLoadingAddData = false;
    },
  },
};
</script>

<style lang="scss" src="./AddAdditionalFee.scss" scoped />