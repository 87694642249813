const additionalFeeOptionsFormatter = {
  payment_type: {
    fixed: 'Tetap',
    once: 'Satu Kali',
  },
  pricing_method: {
    predefined: 'Ditentukan di Awal',
    defined_by_billing: 'Disesuaikan dengan Tagihan',
  },
  time_unit: {
    daily: 'Harian',
    weekly: 'Mingguan',
    monthly: 'Bulanan',
  },
  fee_unit: {
    kg: 'Kilogram (kg)',
    item: 'Item/pcs',
    person: 'Per orang',
  },
  funnel: {
    booking: 'Booking',
    stay: 'Stay',
  },
  tenant_can_choose: {
    true: 'Ya',
    false: 'Tidak',
  },
};

export { additionalFeeOptionsFormatter };